<template>
    <div>
        <div v-if="!submit_tag" style="width: 100%;float: left;margin-top: 20px">
            <div style="width: 100%;text-align: right;font-size: 1.025rem;padding-right: 10%">
                You can launch a edit vote by fill out the following table.
            </div>
        </div>

        <Card style="margin-top: 20px; width: 80%;text-align:left;float:left; margin-left: 10%;padding: 40px 5% 20px;">
            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px">
                Voting Description:
            </div>
            <p style="font-size: 14px;color:darkgray">You should simply describe your reasons for your case.</p>
            <Input v-model="ct.description" type="textarea" placeholder="Enter something..."/>


            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px; margin-top:50px;">
                Project Description:
            </div>
            <Input v-model="ct.abstractInfo" type="textarea" placeholder="Enter something..."/>

            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px; margin-top:50px;">
                Environment:
            </div>
            <Input v-model="ct.environment" type="textarea" placeholder="Enter something..."/>


            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px; margin-top:50px;">
                dependencies:
            </div>
            <Input v-model="ct.dependencies" type="textarea" placeholder="Enter something..."/>

            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-bottom: 5px; margin-top:50px;">
                languages:
            </div>
            <Input v-model="ct.languages" type="textarea" placeholder="Enter something..."/>

            <div class="input_domain">
                <span style="font-size: 1.125rem;">Estimated code size: </span>
                <Input v-model="ct.codeLines" style="width: 18.75rem;">
                    <span slot="append">lines</span>
                </Input>
            </div>

            <div class="input_domain">
                <p style="font-size: 1.125rem; margin-bottom: 0.25rem;">Share point gain ratio(code): </p>
                <p style="font-size: 14px;color:darkgray">Default ratio: 100(lines)=1(share point)</p>
                <Input v-model="ct.codeLineLimitsForOneShare" placeholder="How many lines exchange 1 point"
                       style="width: 28rem;">
                    <span slot="append">lines = 1 point</span>
                </Input>
            </div>
            <div class="input_domain">
                <p style="font-size: 1.125rem; margin-bottom: 0.25rem;">Share point gain ratio(invest): </p>
                <Input v-model="ct.moneyorGetOneShare" placeholder="How much" style="width: 20rem;">
                    <span slot="prepend"> $ </span>
                    <span slot="append">= 1 point</span>
                </Input>
            </div>

            <div class="input_domain">
                <p style="font-size: 1.125rem; margin-bottom: 0.25rem;">Price of executable code: </p>
                <Input v-model="ct.executableCodePrice" placeholder="How much" style="width: 20rem;">
                    <span slot="prepend"> $ </span>
                </Input>
            </div>


            <div class="input_domain">
                <span style="font-size: 1.125rem;">Voting time for Join/Extend: </span>
                <Input v-model="ct.votingTimeForJoin" placeholder="" style="width: 20rem;">
                    <span slot="append">minutes</span>
                </Input>
            </div>
            <div class="input_domain">
                <span style="font-size: 1.125rem;">Voting time for amendment: </span>
                <Input v-model="ct.votingTimeForAmendment" placeholder="" style="width: 20rem;">
                    <span slot="append">days</span>
                </Input>
            </div>


            <div style="font-size: 1.025rem;font-weight: 500; padding-right: 15px;margin-top:50px;margin-bottom: 5px">
                Price of Source Code
                <p style="font-size: 14px;color:darkgray;display: inline">(Dollars per 100 lines of code)</p>:
            </div>
            <p v-if="!submit_tag" style="font-size: 14px;color:darkgray"> You can just give the Initial price. You can
                also give profit-price to adjust price in profit increasing.</p>
            <div class="table_unit"
                 style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">

                <div style="width:40%; float:left;display:inline">
                    Total Profit
                </div>
                <div style="width:40%;margin-left:3%;float:left;display:inline">
                    Price
                </div>
            </div>
            <Form  style="width: 100%;float:left">
                <FormItem
                        v-for="(item, index) in priceList"
                        :key="index"
                        :prop="'items.' + index + '.value'"
                >
                    <div class="table_unit" style="width:100%; float:left;">
                        <div class="table_unit" style="width:40%; float:left;display:inline">
                            <div style="text-align: left;padding-left: 10%" v-if="index===0">0</div>
                            <Input prefix="logo-usd" v-if="!submit_tag && index>0" type="number" v-model="item.profit"
                                   placeholder="Enter something..."></Input>
                            <div style="text-align: left;padding-left: 10%" v-if="submit_tag && index >0">
                                {{item.profit}}
                            </div>
                        </div>
                        <div class="table_unit" style="width:40%; margin-left:3%;float:left;display:inline">
                            <Input prefix="logo-usd" v-if="!submit_tag" type="number" v-model="item.price"
                                   placeholder="Enter something..."></Input>
                            <div style="text-align: left;padding-left: 10%" v-if="submit_tag ">{{item.price}}</div>
                        </div>
                        <div v-if="!submit_tag" class="table_unit"
                             style="width:10%; margin-left: 3%; float:left;display:inline">
                            <Button long v-if="index===priceList.length-1" @click="handleRemove1(index)">Delete</Button>
                        </div>
                        <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                        </hr>
                    </div>
                </FormItem>
                <FormItem v-if="!submit_tag">
                    <div>
                        <Button type="dashed" long @click="handleAdd1" icon="md-add"
                                style="width: 30%;float: left;margin-left: 35%">Add item
                        </Button>
                    </div>
                </FormItem>
            </Form>


            <div class="input_domain">
                <span style="font-size: 1.125rem;">Hierarchy diagram of the project: </span>
                <div v-if="ct.hierarchyDiagramPath.length != 34" style="">
                    <img style="width: 50rem;margin-top: 1rem;" :src="ct.hierarchyDiagramPath"/>
                </div>
                <div>
                    <Upload v-if="file===''"
                            :before-upload="handleUpload"
                            action="">
                        <Button icon="ios-cloud-upload-outline">Select the file to update the hierarchyDiagram</Button>
                    </Upload>
                    <div v-else>
                        {{file.name}}
                    </div>
                </div>
            </div>


            <div class="input_domain">
                <span style="font-size: 1.125rem;">FunctionList </span>
                <div class="table_unit"
                     style="width: 100%;height:40px; font-size:1.0125rem; padding-top:10px;margin-bottom:20px;padding-left:3%;text-align:left;float:left; background-color: #F5F5F5">
                    <div style="width:10%; float:left;display:inline">
                        id
                    </div>
                    <div style="width:35%; float:left;display:inline">
                        name
                    </div>
                    <div style="width:35%;margin-left:3%;float:left;display:inline">
                        description <strong style="display: inline;font-size: 14px;color:darkgray">( optional )</strong>
                    </div>
                </div>
                <Form style="width: 100%;float:left">
                    <FormItem
                            v-for="(item,index) in functionList"
                            :key="index"
                            :prop="'items.' + index + '.value'"
                    >
                        <div class="table_unit" style="width:100%; float:left;">
                            <div class="table_unit" style="width:11%; float:left;display:inline">
                                Function {{index+1}}
                            </div>
                            <div class="table_unit" style="width:35%; float:left;display:inline">
                                <div>
                                    <Input v-model="item.name"></Input>
                                </div>
                            </div>
                            <div class="table_unit" style="width:35%; margin-left:3%;float:left;display:inline">
                                <div>
                                    <Input v-model="item.description"></Input>
                                </div>
                            </div>
                            <div class="table_unit" style="width:8%; margin-left:2%;float:left;display:inline">
                                <Button long v-if="index>0" @click="handleRemove2(index)">Delete</Button>
                            </div>
                            <hr style="width:100%; float:left; margin-top:12px;height:1px; border:none; background-color:#F5F5F5">
                            </hr>
                        </div>
                    </FormItem>
                    <FormItem>
                        <div>
                            <Button type="dashed" long @click="handleAdd2" icon="md-add"
                                    style="width: 30%;float: left;margin-left: 35%">Add item
                            </Button>
                        </div>
                    </FormItem>
                </Form>
            </div>

            <div style="margin-top: 40px;width: 40%;margin-left: 30%">
                <Button v-if="!submit_tag" type="primary" @click="submit_update()" long>Submit</Button>
                <Modal v-model="modal2"
                       title="Submit your application."
                       @on-ok="real_submit"
                       @on-cancel="cancel"
                       width="600"
                >
                    <p>Do you confirm your case?</p>
                </Modal>

                <Button v-if="submit_tag" type="primary" @click="edit2()" long>Edit</Button>
            </div>
        </Card>
        <div v-if="submit_tag" style="width: 100%;float: left;margin-top: 30px">
            <Divider></Divider>
            <div style="width: 100%;float: left;font-size: 1.125rem;text-align: left;font-weight: 500">
                Voting details
            </div>
            <voting-pic v-bind:reject="reject" v-bind:pending="pending" v-bind:approve="approve"></voting-pic>
            <Divider></Divider>
            <div style="width: 100%; float: left">
                <List item-layout="vertical">
                    <div v-for="item in data" :key="item.title" style="width: 100%;float: left; min-height: 100px">
                        <div style="width: 30%;padding-bottom:30px;float: left">
                            <div style="width: 100%;float: left">
                                <Avatar icon="ios-person" size="70"/>
                            </div>
                            <div style="width: 100%;float: left;margin-top: 20px;font-weight: 500;font-size: 1.025rem">
                                {{item.user_name}}
                            </div>
                        </div>
                        <div style="width: 60%;float: left">
                            {{ item.content }}
                        </div>
                        <Divider></Divider>
                    </div>
                </List>
            </div>
        </div>
    </div>

</template>

<script>
    import CountD from "./countD";
    import VotingPic from "./votingPic";

    export default {
        name: "projectInformationEdit",
        components: {VotingPic, CountD},
        data() {
            return {
                file: '',
                userName: "",
                vote_state: false,
                reject: 100,
                pending: 100,
                approve: 100,
                share: 0,
                modal1: false,
                modal2: false,
                endDate: null,
                submit_tag: false,
                data: [],
                joinVotingTime: '',
                amendmentVotingTime: '',
                columns1: [
                    {
                        title: 'Total Profit',
                        key: 'profit'
                    },
                    {
                        title: 'Price',
                        key: 'price'
                    }
                ],

                description: null,
                formDynamic: {
                    items: [
                        {
                            profit: '',
                            price: '',
                            index: 1,
                            status: 1,
                        },
                        {
                            profit: '',
                            price: '',
                            index: 2,
                            status: 2,
                        },

                    ]
                },
                formDynamic2: {
                    items: [
                        {
                            profit: '',
                            price: '',
                            index: 1,
                            status: 1,
                        },
                        {
                            profit: '',
                            price: '',
                            index: 2,
                            status: 2,
                        },

                    ]
                },

                ct: {
                    userId:"",
                    description: "",
                    abstractInfo: "",
                    codeLineLimitsForOneShare: 0,
                    codeLines: 0,
                    contributionNumber: 0,
                    creationTime: "",
                    dependencies: "",
                    environment: "",
                    executableCodePath: "",
                    executableCodePrice: 0,
                    founder: "",
                    functionList: [],
                    functionNumber: 0,
                    hierarchyDiagramPath: "",
                    hierarchyList: [],
                    id: "",
                    investorNumber: 0,
                    languages: "",
                    linesForGetOneShare: 0,
                    moneyorGetOneShare: 0,
                    name: "",
                    releaseTime: "",
                    sourceCodePrice: 0,
                    totalProfit: 0,
                    updateTime: "",
                    votingTimeForAmendment: 0,
                    votingTimeForJoin: 0,
                    priceList:[]
                },
                functionList: [],
                priceList:[]

            }

        },
        methods: {
            agree() {
                this.modal1 = true;
            },
            disagree() {
                this.modal2 = true;
            },
            OK1() {
                if (this.endDate === null) {
                    let curDate = new Date();
                    this.endDate = new Date(curDate.getTime() + 5 * 60 * 1000);
                }
                this.approve = this.approve + this.share;
                this.pending = this.pending - this.share;
                this.vote_state = true;
                console.log(this.endDate);
                console.log(this.approve);
            },
            OK2() {
                if (this.endDate === null) {
                    let curDate = new Date();
                    this.endDate = new Date(curDate.getTime() + 5 * 60 * 1000);
                }
                this.reject = this.reject + this.share;
                this.pending = this.pending - this.share;
                this.vote_state = true;
                console.log(this.endDate);
                console.log(this.reject);
            },
            submit2() {
                this.modal2 = true;
            },
            real_submit() {
                let type = 'success';
                this.$Message[type]({
                    background: true,
                    content: 'Success!'
                });
                this.submit_tag = true;
            },
            handleRemove1(index) {
                this.priceList.pop();
            },
            handleRemove2(index) {
                this.functionList.splice(index,index);
            },
            handleRemove22(index) {
                this.formDynamic2.items[index].status = 0;
            },
            handleAdd1() {
                this.index++;
                this.priceList.push({
                    profit: '',
                    price: '',
                });
            },
            handleAdd2() {
                this.functionList.push({
                    name: '',
                    description: " "
                });
            },
            cancel() {

            },
            edit2() {
                this.submit_tag = false;
            },
            init() {
                if (this.$cookies.isKey('user_name')) {
                    var identity = this.$cookies.get('user_identity');
                    if (identity == 'member') {
                        this.isMember = true;
                    } else {
                        this.isMember = false;
                    }
                } else {
                    this.isMember = false;
                }
                var Tself = this;
                var val = this.$route.query.id;
                this.projectId = val;
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/project/findById',
                    data: val
                })
                    .then(function (response) {
                        var data = response.data.data.items;
                        Tself.ct = data

                        for (let i = 0; i < data.functionList.length; i++) {
                            let str = data.functionList[i].split("---")
                            Tself.functionList.push({
                                    name: str[0],
                                    description: str[1]
                                }
                            )
                        }
                        for (let i = 0; i < data.priceList.length; i++) {
                            let str = data.priceList[i].split("---")
                            Tself.priceList.push({
                                    profit: str[0],
                                    price: str[1]
                                }
                            )
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            handleUpload(file) {
                this.file = file;
                let fd = new FormData();
                fd.append('image', (this.file))
                this.$axios.post(
                    "/uploadImage",
                    fd,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((response) => {
                    this.ct.hierarchyDiagramPath = response.data.data.filePath;
                }).catch((error) => {
                    console.log(error)
                    this.$Message.error(error.message);
                })
                return false;
            },
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            submit_update() {

                let fcl = this.functionList.map(a => a.name + "---" + a.description)
                this.ct.functionNumber = fcl.length;
                this.ct.userId = this.getCookie("user_id")
                this.ct.functionList = fcl;
                let priceList = this.priceList.map(a=>a.profit+'---'+a.price)
                console.log(this.priceList)
                this.ct.priceList = priceList;
                var lopls = JSON.stringify(this.ct);

                console.log(lopls)

                this.$axios({
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        method: 'put',
                        url: "/projectEdit/update",
                        data: lopls
                    }
                ).then((response) => {
                    let pjid = response.data.data.items.projectId;
                    if (pjid !== null && pjid !== undefined && pjid !== "") {
                        this.$Message.success("submit success")
                        location.href = '/#/projectDetail?id=' + (pjid);
                    } else {
                        this.$Message.error("submit error")
                    }

                }).catch((error) => {
                    console.log(error);
                })

            },
        },
        mounted() {
            this.init()
        },
        watch: {
            endDate(newVal, oldVal) {
                this.endDate = newVal
            }
        }


    }
</script>

<style scoped>
    .petitle {
        text-align: left;
        font-size: 1.025rem;
        margin-bottom: 15px;
    }

    .input_domain {
        width: 100%;
        margin-bottom: 16px;
    }
</style>
